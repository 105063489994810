import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';

import { Observable } from 'rxjs';
import { MaterialNumberDto } from '../models/change-sar/material-number.model';
import { MmsChangeDataDto } from '../models/change-sar/change-data-generate-excel-item.model';
import { TypeOfChangeGridData } from '../models/change-sar/change-data-new-item-detail.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaterialNumberJseService {
  constructor(private httpUtilityService: HttpUtilityService) { }

  validateMaterialNumber(data: string[]): Observable<MaterialNumberDto> {
    const materialNumbersQueryParam = data.map(tag => encodeURIComponent(tag)).join(',');
    const url = `api/MaterialJde/validate?materialNumbers=${materialNumbersQueryParam}`;
    return this.httpUtilityService.get<MaterialNumberDto>(url);
  }

  getMmsChangeData(data: TypeOfChangeGridData[]): Observable<MmsChangeDataDto[]> {
    let params = new HttpParams();
    data.forEach((item, index) => {
      params = params.append(`data[${index}]`, JSON.stringify(item));
    });

    const url = `api/MaterialJde/change-data`;
    return this.httpUtilityService.get<MmsChangeDataDto[]>(url, { params });
  }
}
