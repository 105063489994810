import { Component, OnDestroy, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { forkJoin } from 'rxjs';
import { ChangeDataConst, ChangeDataEnum } from 'src/app/core/constants/change-data.const';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { BranchPlantDetails, ChangeDataGenerateExcelItem, MmsChangeDataDto } from '../../../models/change-sar/change-data-generate-excel-item.model';
import { BranchPlant, BranchPlantDto, ChangeDataMatNumber, ChangeTypeDetailsDto, MatChangeTypeDetailsDto, MatNumberChangeDetails, MatNumChangeTypeDto, TypeOfChange, TypeOfChangeGridData } from '../../../models/change-sar/change-data-new-item-detail.model';
import { ChangeTypeDto } from '../../../models/change-sar/change-type.model';
import { ChangeDataService } from '../../../services/change-data.service';
import { saveAs } from 'file-saver';
import { ChangeDataExcelColumns } from 'src/app/core/constants/excel-columns-const';
import { TempActionService } from '../../../services/temp-action.service';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';
import { MaterialNumberJseService } from '../../../services/material-number-jde.service';
import { MaterialNumberDto } from '../../../models/change-sar/material-number.model';
import * as JSZip from 'jszip';
import { StockingLocationsService } from 'src/app/features/setup/services/stocking-locations.service';

@Component({
  selector: 'app-change-data-excel-template',
  templateUrl: './change-data-excel-template.component.html',
  styleUrls: ['./change-data-excel-template.component.scss']
})
export class ChangeDataExcelTemplateComponent implements OnInit, OnDestroy {
  requestNumber: string = '';
  requestIssueId: string = '';
  isNew: boolean = true;
  navData: any;
  materialList: [] = [];
  enteredmaterialNumbers: string = '';

  mmsChangeData: MmsChangeDataDto[] = [];
  typeOfChangeGridData: TypeOfChangeGridData[] = [];
  validMaterialNumbers: string[] = [];
  selectedMaterialNumbers: string[] = [];
  typesOfChange: ChangeTypeDto[] = [];
  selectedTypesOfChange: ChangeTypeDto[] = [];
  branchPlants: string[] = [];
  selectedBranchPlants: string[] = [];
  isBranchPlantDisabled: boolean = true;
  isButtonTypeOfChangeDisabled: boolean = true;
  assetName: string = '';

  materialNumberlist: Array<ChangeDataMatNumber> = [];
  selectedMaterialNumbersList: Array<ChangeDataMatNumber> = [];
  buName: string = '';
  excelFileName: string = 'ChangeSARExcelTemplate';
  excelFilePath: string = 'ChangeSARExcelTemplate';

  matNumberchangeTypes: ChangeTypeDto[] = [];
  selectedValues: ChangeTypeDto[] = [];
  changedataInfo: ChangeTypeDetailsDto[] = [];
  removingItem: any;
  removingPlant: any;
  public isOpen: boolean = false;
  public mySelection: number[] = [1];
  public readonly changeDataEnum: typeof ChangeDataEnum = ChangeDataEnum;

  @ViewChild("multiselectChangeType", { static: true }) public multiselectChangeType: any;
  @ViewChild("multiselectPlantList", { static: true }) public multiselectPlantList: any;
  @ViewChild('myGrid', { static: true }) myGrid!: GridComponent;
  @ViewChild('otherTextArea') kendoTextArea!: ElementRef<TextAreaComponent>;
  @ViewChild('headerCheckbox') headerCheckbox!: ElementRef<boolean>
  isShowTypeOfChangeDataList: boolean = false;
  plantList: BranchPlantDetails[] = [];
  plantDefaultvalue: BranchPlantDetails = { branchPlantCode: '-- Please Select a Branch Plant --', branchPlantCurrentValue: 'default' };
  selectedPlants: BranchPlantDetails[] = [];
  existingData: { materialNumber: string | undefined, changeType: string | undefined, status: string | undefined, plantOtherChangeType: string }[] = [];
  currentChangeDataInfo: ChangeTypeDetailsDto[] = [];
  selectedMaterialNumber: string = '';
  isShowPlantsDropdown: boolean = false;
  isShowOther: boolean = false;
  otherValue: string = '';
  oldOtherValue: string = '';
  excelFileData: ChangeDataGenerateExcelItem[] = [];
  selectedChangeTypeId?: number;
  changeDataExcelColumns: any = ChangeDataExcelColumns;
  ChangeDataConst: any = ChangeDataConst;
  rowSelectedIndex?: number;
  ischecked: boolean = false;
  selectedItems: any = [];
  note: string[] = [];
  toasterMsg?: string | undefined = '';
  matNumChangeTypeDetails: MatNumberChangeDetails[] = [];
  isNoteVisible: boolean = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private materialInfoService: MaterialInfoService,
    private renderer: Renderer2,
    private el: ElementRef,
    private changeDataService: ChangeDataService,
    private tempActionService: TempActionService,
    private materialNumberJdeService: MaterialNumberJseService,
    private stockingLocationsService: StockingLocationsService) {

    this.buName = this.commonService.getUserBUName();
    this.navData = this.router.getCurrentNavigation()?.extras.state;

    if (!this.commonService.isNullOrEmptyOrUndefined(this.navData)) {
      this.existingData = this.navData['existingData'];
      this.requestNumber = this.navData['requestNumber'];
      this.requestIssueId = this.navData['requestIssueId'];
      this.isNew = this.navData['isNew'];
      this.assetName = this.navData['assetName']
      sessionStorage.setItem('sarnewaction', JSON.stringify(this.navData));
    }
    else {
      const sessionStorageData = JSON.parse(sessionStorage.getItem('sarnewaction')!);
      if (!this.commonService.isNullOrEmptyOrUndefined(sessionStorageData)) {
        this.assetName = sessionStorageData.assetName;
        this.existingData = sessionStorageData.existingData;
        this.requestNumber = sessionStorageData.requestNumber;
        this.requestIssueId = sessionStorageData.requestIssueId;
        this.isNew = sessionStorageData.isNew
        this.navData = sessionStorageData;
      }
    }
  }

  ngOnInit(): void {
    this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
    this.getMaterialList();
    this.getMatNumberchangeTypes();
    this.changeDataService.getChangeTypes().subscribe((response: ChangeTypeDto[]) => {
      this.typesOfChange = response
    });
  }

  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      this.materialList = outputDtata;
    });
  }

  navBack() {
    this.router.navigate(['./newaction'], { state: { isNew: this.isNew, requestId: this.navData.requestId, requestNumber: this.navData.requestNumber, requestIssueId: this.requestIssueId, actionNumber: this.navData.actionNumber, changeType: this.navData.changeType, assetName: this.navData.assetName }, relativeTo: this.route.parent });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('sarnewaction');
  }

  onValidate() {
    this.note = [];
    let originalData: string[] = this.enteredmaterialNumbers.split(/[\n\t\s,]+/).filter(num => num !== '');
    let data: string[] = this.removeduplicates(originalData);
    let wrongMaterialNumbers: string[] = [];
    let notificationMessage: string = '';
    let messageType: 'success' | 'warning' | 'error' = 'success';

    this.materialNumberJdeService.validateMaterialNumber(data).subscribe(
      (response: MaterialNumberDto) => {
        this.validMaterialNumbers = response.validMaterialNumbers;
        wrongMaterialNumbers = data.filter((x: string) => !this.validMaterialNumbers.includes(x));
        notificationMessage = `${this.validMaterialNumbers.length} valid material numbers were added, `;

        if (wrongMaterialNumbers.length > 0) {
          notificationMessage += `Wrong material numbers count: ${wrongMaterialNumbers.length} were ignored because of the wrong addition`;
          messageType = 'warning';
        }
        else {
          messageType = 'success';
        }
        this.commonService.showNotificationMessage(notificationMessage, messageType, 'center');
        this.enteredmaterialNumbers = '';
      });
  }

  onValueChange(selectedValues: TypeOfChange[]) {
    let hasExtendBranchPlant = this.selectedTypesOfChange
      .find(item => item.label === 'Stocking Type') !== undefined
    
    
    if (this.selectedTypesOfChange.length > 0) {
      this.isButtonTypeOfChangeDisabled = false;
    }
    else {
      this.isButtonTypeOfChangeDisabled = true;
    }

    if (hasExtendBranchPlant && this.selectedTypesOfChange.length == 1) {
      this.isBranchPlantDisabled = false;
      
      this.stockingLocationsService.getStockingLocationCodes(this.assetName).subscribe((response) => {
        this.branchPlants = response;
      });
    }
    else {
      this.isBranchPlantDisabled = true;
      this.branchPlants = [];
    }
  }

  onAddTypeOfChange(): void {
    for (let typeOfChange of this.selectedTypesOfChange) {
      let item = new TypeOfChangeGridData(typeOfChange.label!, this.selectedMaterialNumbers.join(', '), this.selectedBranchPlants.join(', '), this.selectedMaterialNumbers);
      this.typeOfChangeGridData.push(item);
    }
  }

  setMaterialList(validMaterialNumbers: string[]) {
    this.materialNumberlist = [];
    for (var i = 0; i < this.validMaterialNumbers.length; i++) {
      let matNumber = new ChangeDataMatNumber();
      matNumber.id = i;
      matNumber.materialNumber = this.validMaterialNumbers[i];
      this.materialNumberlist.push(matNumber);
    }
  }

  removeduplicates(table: any[]) {
    const uniqueArray = table.reduce((acc, current) => {
      if (!acc.includes(current))
        acc.push(current);
      return acc
    }, []);
    return uniqueArray;
  }

  clearValidMatNumbers() {
    this.note = [];
    this.toasterMsg = '';
    this.validMaterialNumbers = [];
    this.excelFileData = [];
    this.clearAndHideFieldsData();
    this.selectedItems = [];
    this.materialNumberlist = [];
    this.selectedMaterialNumbersList = [];
  }

  selectAll() {
    this.clearAndHideFieldsData();
    this.selectedMaterialNumbersList = [];
    this.headerCheckbox = this.el.nativeElement.querySelector("#headerCheckbox").checked;
    if (this.headerCheckbox) {
      this.materialNumberlist.forEach((item: any) => {
        this.selectedMaterialNumbersList.push(item);
      })
      if (this.selectedMaterialNumbersList.length > 0) {
        this.isShowTypeOfChangeDataList = true;
        if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
          this.getBranchPlants();
        }
      }
    }
  }

  onCheckMaterialNumber(item: ChangeDataMatNumber) {
    let recordExist = this.selectedMaterialNumbersList.filter((y: ChangeDataMatNumber) => y.materialNumber === item.materialNumber)[0];
    if (recordExist) {
      this.selectedMaterialNumbersList = this.selectedMaterialNumbersList.filter((y: ChangeDataMatNumber) => y.materialNumber !== item.materialNumber);
    }
    else {
      this.selectedMaterialNumbersList.push(item);
    }
    this.clearAndHideFieldsData();
    if (this.selectedMaterialNumbersList.length > 0) {
      this.isShowTypeOfChangeDataList = true;
      if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
        this.getBranchPlants();
      }
    }
  }

  onMaterialNumberClick({ rowIndex }: CellClickEvent) {
    this.clearAndHideFieldsData();
    this.selectedMaterialNumbersList = [];
    this.selectedMaterialNumbersList.push(this.materialNumberlist[rowIndex]);
    this.isShowTypeOfChangeDataList = true;
  }

  removeRow(dataItem: any) {
    this.note = [];
    this.toasterMsg = '';
    let recordIndex = this.excelFileData.findIndex((item: ChangeDataGenerateExcelItem) => (item.materialNumber == dataItem.materialNumber && item.changeType == dataItem.changeType));
    if (recordIndex > -1) {
      this.excelFileData.splice(recordIndex, 1);
    }
  }

  clearAndHideFieldsData() {
    this.changedataInfo = [];
    this.isShowTypeOfChangeDataList = false;
    this.selectedValues = [];
    this.removingItem = null;
    this.isShowOther = false;
    this.otherValue = '';
    this.oldOtherValue = '';
    this.isShowPlantsDropdown = false;
    this.selectedPlants = [];
    this.plantList = [];
    this.removingPlant = null;
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.index == this.rowSelectedIndex) {
      return { background: true };
    } else {
      return { background: false };
    }
  }

  getMatNumberchangeTypes() {
    let getChangeTypesAPI = this.changeDataService.getChangeTypes();
    forkJoin([getChangeTypesAPI]).subscribe(([getChangeTypesData]) => {
      this.selectedValues = [];
      this.matNumberchangeTypes = getChangeTypesData;
    });
  }
  setPlant(plantCode: string): BranchPlantDetails {
    let plant: BranchPlantDetails = new BranchPlantDetails();
    plant.branchPlantCode = plantCode;
    plant.branchPlantCurrentValue = '';
    plant.status = true;
    return plant;
  }
  removeChangeType(event: any) {
    if (event.dataItem.id === this.changeDataEnum.leadTime) {
      this.isShowPlantsDropdown = false;
      this.selectedPlants = [];
    }
    if (event.dataItem.id === this.changeDataEnum.other) {
      this.isShowOther = false;
      this.oldOtherValue = '';
      this.otherValue = '';
    }
    this.removingItem = event.dataItem;
  }
  selectChangeType(event: any) {
    if (event[event.length - 1].id === this.changeDataEnum.leadTime) {
      this.isShowPlantsDropdown = true;
    }
    if (event[event.length - 1].id === this.changeDataEnum.other)
      this.isShowOther = true;

    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0) {
      this.getBranchPlants();
    }

  }

  selectPlantChange(event: any) {
    this.selectedPlants = event;
  }

  setChangeDataGenerateExcelItem(type: MatChangeTypeDetailsDto, materialNum?: string, plantOtherChangeType?: string) {
    let changeDataGenerateExcelItem: ChangeDataGenerateExcelItem = new ChangeDataGenerateExcelItem();
    changeDataGenerateExcelItem.materialNumber = materialNum;
    changeDataGenerateExcelItem.changeType = type.changeType;
    changeDataGenerateExcelItem.plantOtherChangeType = plantOtherChangeType ? plantOtherChangeType : '';
    changeDataGenerateExcelItem.oldData = type.oldData ? type.oldData : '';
    return changeDataGenerateExcelItem
  }

  changeChangeTypesStatus(selectedValues: any) {
    this.matNumberchangeTypes.forEach((element: any) => {
      if (selectedValues.findIndex((x: any) => x.id === element.id) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }

  removePlant(event: any) {
    this.removingPlant = event.dataItem;
  }
  plantChangeStatus(selectedValues: any) {
    this.plantList.forEach((element: any) => {
      if (selectedValues.findIndex((x: any) => x.branchPlantCode === element.branchPlantCode) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }
  onOpenChangeType(event: any) {
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    this.renderer.addClass(multiselectChangeType, 'opened');
  }
  onCloseChangeType(event: any) {
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    this.isOpen = false;
    this.renderer.removeClass(multiselectChangeType, 'opened');
  }
  toggleChangeType() {
    this.isOpen = !this.isOpen;
    this.multiselectChangeType.toggle(this.isOpen);
    let multiselectChangeType;
    multiselectChangeType = this.el.nativeElement.querySelector("#multiselectChangeType");
    if (this.isOpen) {
      this.renderer.addClass(multiselectChangeType, 'opened')
    }
    else {
      this.renderer.removeClass(multiselectChangeType, 'opened');
    }
  }
  onOpenPlant(event: any) {
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    this.renderer.addClass(multiselectPlantList, 'opened');
  }
  onClosePlant(event: any) {
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    this.isOpen = false;
    this.renderer.removeClass(multiselectPlantList, 'opened');
  }
  togglePlant() {
    this.isOpen = !this.isOpen;
    this.multiselectPlantList.toggle(this.isOpen);
    let multiselectPlantList;
    multiselectPlantList = this.el.nativeElement.querySelector("#multiselectPlantList");
    if (this.isOpen) {
      this.renderer.addClass(multiselectPlantList, 'opened')
    }
    else {
      this.renderer.removeClass(multiselectPlantList, 'opened');
    }
  }
  public downloadExcelTemplate(): void {
    if (this.validMaterialNumbers.length === 0) {
      this.commonService.showNotificationMessage('Please add the validated material numbers', 'error', 'center');
    }
    else if (this.selectedTypesOfChange.length === 0) {
      this.commonService.showNotificationMessage('Please add change types for the validated material numbers by selecting them', 'error', 'center');
    }
    else {
      this.downloadRawAndTemplateFiles();
    }
  }

  downloadRawAndTemplateFiles(): void {
    const zip = new JSZip();
    zip.file("ChangeSAR_Raw_Data.xlsx", this.exportToExcel());

    this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
      this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
        const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
        let date = this.commonService.formatDateTo_ddmmyyyy(new Date());
        const fileName = this.requestNumber + '-' + this.excelFileName + '-' + date + '.xlsx';
        zip.file(fileName, blobdownload);

        this.tempActionService.fetchBlobUrl(BlobURlsDetails.changeSARExcelTemplateUserGuide).subscribe((responseUrl: string) => {
          this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
            const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
            zip.file(BlobURlsDetails.changeSARExcelTemplateUserGuide + '.docx', blobdownload);
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, `${this.requestNumber}-ChangeSar-${date}.zip`);
              this.navBack();
            });
          })
        })
      })
    });
  }

  getMatNumChangeTypeDetails() {
    let matNumChangeTypeDto = new MatNumChangeTypeDto();
    let matnumbers: Array<string> = [];
    let changeTypes: Array<string> = [];
    this.selectedMaterialNumbersList.forEach((x: ChangeDataMatNumber) => {
      matnumbers.push(x.materialNumber!);
    })
    this.selectedValues.forEach((x: ChangeTypeDto) => {
      changeTypes.push(x.label!);
    });
    matNumChangeTypeDto.MaterialNumbers = matnumbers;
    matNumChangeTypeDto.ChangeTypes = changeTypes;
    matNumChangeTypeDto.BuName = this.buName;
    if (this.isPlantorOtherIncluded()) {
      this.changeDataService.getMatNumChangeTypeDetails(matNumChangeTypeDto).subscribe((outputData: MatNumberChangeDetails[]) => {
        this.matNumChangeTypeDetails = outputData;
        this.validateChangeTypes();
      });
    }
  }

  isPlantorOtherIncluded() {
    let neededNotification: number = 0;
    let message: string = '';
    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Lead Time').length > 0 && this.selectedPlants.length === 0) {
      neededNotification++;
      message = "Please select plants before proceeding";
    }
    if (this.selectedValues.filter((x: ChangeTypeDto) => x.label == 'Other').length > 0 && this.otherValue === '') {
      neededNotification++;
      message = "Please provide other value before proceeding";
    }
    if (neededNotification > 0) {
      message = neededNotification === 2 ? 'Please select plants and provide other value before proceeding' : message;
      this.commonService.showNotificationMessage(message, 'error', 'center');
    }
    return neededNotification > 0 ? false : true;
  }
  getBranchPlants() {
    let branchDto = new BranchPlantDto();
    let matnumbers: Array<string> = [];
    this.selectedMaterialNumbersList.forEach((x: ChangeDataMatNumber) => {
      matnumbers.push(x.materialNumber!);
    })
    branchDto.MaterialNumbers = matnumbers;
    branchDto.BuName = this.buName;
    this.changeDataService.getBranchPlantDetail(branchDto).subscribe((outputData: any) => {
      this.plantList = outputData;
    });
  }

  showAndHideNote() {
    this.isNoteVisible = !this.isNoteVisible;
  }

  onAddClick() {
    this.note = [];
    this.isNoteVisible = false;
    this.getMatNumChangeTypeDetails();
  }

  validateChangeTypes() {
    this.toasterMsg = '';
    this.selectedMaterialNumbersList.forEach((item: ChangeDataMatNumber) => {
      let existingChangeTypes: string[] = [];
      this.matNumChangeTypeDetails.filter((data: MatNumberChangeDetails) => data.materialNumber === item.materialNumber)[0].changeTypeValues?.forEach((type: MatChangeTypeDetailsDto) => {
        if (type.changeType === 'Lead Time') {
          let _unavailablePlants: string[] = [];
          let _existingPlants: string[] = this.existingData.filter((z: any) => z.materialNumber == item.materialNumber && z.changeType == type.changeType && z.status !== 'Cancelled')
            .map((x) => { return x.plantOtherChangeType });
          let _addedPlants: string[] = this.excelFileData.filter((y: any) => y.changeType == type.changeType && y.materialNumber === item.materialNumber)
            .map((plant: ChangeDataGenerateExcelItem) => { return plant.plantOtherChangeType; });
          let toasterPlants: string[] = [];
          let notePlants: string[] = [];
          this.selectedPlants?.forEach((plantDet: BranchPlantDetails) => {
            if (_existingPlants.filter(x => x === plantDet.branchPlantCode).length > 0) {
              notePlants.push(plantDet.branchPlantCode!);
            }
            else if (_addedPlants.filter(x => x === plantDet.branchPlantCode).length > 0) {
              toasterPlants.push(plantDet.branchPlantCode!);
            }
            else if (type.branchPlants!.filter((det: BranchPlant) => det.branchPlantCode == plantDet.branchPlantCode).length === 0) {
              _unavailablePlants.push(plantDet.branchPlantCode!);
            }
            else {
              this.addItemToTable(type, item.materialNumber, plantDet.branchPlantCode);
            }
          });
          if (notePlants.length) {
            let _lastPlant: string = notePlants.splice(notePlants.length - 1, 1)[0];
            this.note.push("**" + item.materialNumber + ": Data change type " + type.changeType + " with " + (notePlants.length === 0 ? `plant ${_lastPlant}` : `plants ${notePlants.join(', ')} and ${_lastPlant}`) + " already exists.");
          }
          if (toasterPlants.length) {
            this.toasterMsg += "**" + (toasterPlants.length === 1 ? `plant ${toasterPlants[0]} is` : `plants ${toasterPlants.join(',')} are`) + " already present for this lead time in below table";

          }
        }
        else if (type.changeType === 'Other') {
          this.addItemToTable(type, item.materialNumber, this.otherValue);
        }
        else {
          let changeTypeDet = this.existingData.filter((x: any) => x.materialNumber == item.materialNumber && x.changeType == type.changeType && x.status !== 'Cancelled')[0];
          if (changeTypeDet) {
            existingChangeTypes.push(type.changeType!);
            return;
          }
          let excelfileItem: ChangeDataGenerateExcelItem = this.excelFileData.filter((y: any) => y.changeType == type.changeType && y.materialNumber === item.materialNumber)[0];
          if (excelfileItem) {
            this.toasterMsg += "**" + "Data with " + item.materialNumber + " and data change type " + type.changeType + " already exists in below table";
            return;
          }
          this.addItemToTable(type, item.materialNumber, '');
        }
      });
      if (existingChangeTypes.length > 0) {
        let _lastChangeType: string = existingChangeTypes.splice(existingChangeTypes.length - 1, 1)[0];
        this.note.push("**" + item.materialNumber + ": Data change " + (existingChangeTypes.length === 0 ? `type ${_lastChangeType} already exists.` : `types ${existingChangeTypes.join(', ')} and ${_lastChangeType} already exist.`));
      }
    })

    if (this.toasterMsg) {
      this.commonService.showNotificationMessage(this.ChangeDataConst.generateExcelErrorMessage, 'error', 'center');
    }
    this.selectedValues = [];
    this.isShowPlantsDropdown = false;
    this.isShowOther = false;
    this.selectedItems = [];
    this.selectedPlants = [];
    this.otherValue = '';
    this.selectedMaterialNumbersList = [];
    this.isShowTypeOfChangeDataList = false;
  }

  addItemToTable(type: MatChangeTypeDetailsDto, materialNumber: any, plantOtherChangeType?: string) {
    let changeDataGenerateExcelItem: ChangeDataGenerateExcelItem = this.setChangeDataGenerateExcelItem(type, materialNumber, plantOtherChangeType);
    this.excelFileData.push(changeDataGenerateExcelItem);
  }

  public async exportToExcel(): Promise<any> {
    let excelFileDataMain: ChangeDataGenerateExcelItem[] = [];
    
    await this.materialNumberJdeService.getMmsChangeData(this.typeOfChangeGridData).toPromise().then(
      (response: MmsChangeDataDto[] | undefined) => {
        if (response) {
          for (let item of response) {
            for (let typeOfChange of this.typeOfChangeGridData) {
              let obj = new ChangeDataGenerateExcelItem();
              obj.changeType = typeOfChange.typeOfDataChange;
              obj.description = '';
              obj.plantOtherChangeType = typeOfChange.branchPlants;
              obj.materialNumber = item.materialNumber;
              obj.newData = '';
              obj.oldData = item.oldData;
              obj.oemPartNumber = item.oemPartNumber;
              obj.oem = item.oem;
              obj.qcCodes = item.qcCodes;
              obj.requestorNotes = item.requestorNotes;
              obj.vendor = item.vendor;
              excelFileDataMain.push(obj);
            }
          }
          this.excelFileData = this.excelFileData.concat(excelFileDataMain);
        }
      }
    );
    return this.afterSubscribe();
  }

  public afterSubscribe(): any {
    let convertedArr = [];
    for (let item of this.excelFileData) {
      let obj = {
        mmsKey: '',
        lineNumber: '',
        materialItemNumber: item.materialNumber,
        changeType: item.changeType,
        plant: item.changeType === 'Stocking Type' ? item.plantOtherChangeType : '',
        otherChangeType: item.changeType === 'Other' ? item.plantOtherChangeType : '',
        oldData: item.changeType === 'Lead Time' ? (item.oldData ? item.oldData : 0) : item.oldData,
        newData: '',
        requesterNotes: item.requestorNotes,
        description: item.description,
        oem: item.oem,
        oemPartNumber: item.oemPartNumber,
        vendor: item.vendor,
      } as any;
      convertedArr.push(obj)
    }

    return this.commonService.exportAsExcelFileWhenDataCall(convertedArr, this.changeDataExcelColumns, 'ChangeSAR_Raw_Data', true);
  }
}
